import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import creditCardAnim from '../assets/Animation/creditCard.json';
import errorBoathAnim from '../assets/Animation/errorBoath.json';
import Lottie from 'lottie-react';
import invoiceState, { getInvoicesBySessionIdFunc, updateInvoiceBySessionIdFunc } from '../states/invoiceState';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getInvoiceAnagrapicFunc } from '../states/invoiceAnagraphics';
import { jwtDecode } from 'jwt-decode';
import { updateAccountFunc } from '../states/signinState';
import { Button } from 'react-bootstrap';

const StripeInvoiceProcessing = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const invoiceAnagraphic = useSelector((state) => state.invoiceAnagraphic.invoiceAnagraphic);
    const [searchParams, setSearchParams] = useSearchParams();
    const [tryAgain, setTryAgain] = useState(false);
    const [counter, setCounter] = useState(0);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Payment and Package Upgrade are succesfully completed but an error related to invoice occured!");
    const token = localStorage.getItem("token");
    const sessionParam = searchParams.get("session_id").toString().slice(0, -1);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMi slice'
        }
    }


    useEffect(() => {
        const tkn = jwtDecode(token, process.env.JWT_SECRET);
        dispatch(getInvoiceAnagrapicFunc({ idOwner: tkn.id, token: token }));
    }, [])

    useEffect(() => {
       
        if (invoiceAnagraphic[0]) {
            dispatch(getInvoicesBySessionIdFunc({ sessionId: sessionParam, token: token }))
                .then((res) => {
                    if (res.payload.data.length > 0) {
                        dispatch(updateInvoiceBySessionIdFunc({ payload: { idOwner: invoiceAnagraphic[0].idOwner, idAnagraph: invoiceAnagraphic[0].id, sessionId: sessionParam }, token: token }))
                            .then((res) => {
                                if (res.payload.statusCode === 200 && res.payload.data.length > 0) {
                                    const packageId = res.payload.data.length > 0 &&
                                        res.payload.data[0].planId === "price_1PeGCpRsEhNEuZA1NCElTzDE" ?
                                        1 : res.payload.data[0].planId === "price_1Pg463RsEhNEuZA12EQM26sE" ?
                                            2 : res.payload.data[0].planId === "price_1Pg46iRsEhNEuZA1TafYiCTV" ?
                                                3 : 0;
                                    dispatch(updateAccountFunc({ payload: { id: invoiceAnagraphic[0].idOwner, package: packageId, idBilling: res.payload.data[0].idBilling }, token: localStorage.getItem("token") }))
                                        .then((res) => {
                                            if (res.payload.statusCode === 200) {
                                                navigate("/paymentsuccess")
                                            }
                                        })
                                }
                            })
                    } else {
                        if (counter <= 4) {
                            setCounter(counter + 1);
                            setTimeout(() => {
                                setTryAgain(!tryAgain);
                            }, 5000)
                        } else {
                            setError(true);
                            setErrorMessage("Payment and Package Upgrade are succesfully completed but an error related to invoice occured!")
                        }
                    }
                })
        }
    }, [tryAgain, invoiceAnagraphic])

    return (
        <div className='myBgDark py-5 px-3 d-flex flex-column align-items-center justify-content-center' style={{ minHeight: "100vh" }}>
            {
                error ?
                    <div className='px-4'>
                        <h4 className='text-light fw-light text-center'>{errorMessage}</h4>
                    </div>
                    : null
            }
            <div className='position-relative w-100'>
                {
                    error ?
                        <div className="position-relative w-100 myMaxW300" style={{ left: "50%", transform: "translateX(-50%)" }}>
                            <Lottie animationData={errorBoathAnim} options={defaultOptions} />
                        </div>
                        :
                        <div className="position-relative w-100 myMaxW200" style={{ left: "50%", transform: "translateX(-30%)" }}>
                            <Lottie animationData={creditCardAnim} options={defaultOptions} />
                        </div>
                }
            </div>
            {
                error ?
                    <div>
                        <Button className='rounded-0 btn btn-secondary border border-dark text-light px-4 py-2 fw-bold' onClick={() => { window.location.reload() }}><i className="bi bi-arrow-clockwise me-2"></i>Try again</Button>
                        <Button className='rounded-0 btn btn-secondary myBgYellow border border-dark text-light px-4 py-2 fw-bold' onClick={() => { window.location.reload() }}><i className="bi bi-bandaid-fill me-2"></i>Fix it</Button>
                    </div>
                    : null
            }
        </div>
    )
}

export default StripeInvoiceProcessing