import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getInvoicesByIdOwnerFunc, unsubscribePaypalFunc } from '../states/invoiceState';
import { decodeTkn } from '../states/generalState';
import { jwtDecode } from 'jwt-decode';
import { getSingleUserFunc } from '../states/loginState';
import { useNavigate } from 'react-router';
import { Button } from 'react-bootstrap';
import { stripeUnsubscribeFunc } from '../states/unsubscribeState';
import { updateAccountFunc } from '../states/signinState';
import packagesJSON from '../assets/JSON/packages.json';

const PackagesSubscription = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.login.userData);
    const invoicesData = useSelector((state) => state.invoices.invoicesData);
    const [remainingPercentage, setRemainingPercentage] = useState(0);
    const [remainingDays, setRemainingDays] = useState(0);
    const [subscriptionPackage, setSubscriptionPackage] = useState(userData[0] ? userData[0].package : 0);
    const [unsubscribe, setUnsubscribe] = useState(false);
    const [upgrade, setUpgrade] = useState(false);
    const [isUserUnsubscribed, setIsUserUnsubscribed] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const manageUpgrade = async () => {

        if (invoicesData.length > 0 && userData[0]) {
            dispatch(stripeUnsubscribeFunc({ idBilling: invoicesData[0].idBilling, token: localStorage.getItem("token") }))
                .then((res) => {
                    if (res.payload.statusCode === 200) {
                        dispatch(updateAccountFunc({ payload: { id: userData[0].id, unsubscribeTimer: "clear", idBilling: "clear", package: 0 }, token: localStorage.getItem("token") }))
                            .then((res) => {
                                if (res.payload.statusCode === 200) {

                                    if (subscriptionPackage === 1) {
                                        navigate("/upgradepremium");
                                    };
                                    if (subscriptionPackage === 2) {
                                        navigate("/upgradebusiness");
                                    };
                                    if (subscriptionPackage === 3) {
                                        navigate("/upgradeseller");
                                    }
                                }
                            })
                    }
                })
        } else {
            setErrorMessage("A loading error occurred. The page will reload automatically in 5s. Make sure to retry the Upgrade only once the page is fully loaded.");
            setTimeout(() => {
                window.location.reload()
            }, 5000)
        }
    }

    const manageSubscription = async () => {

        if (subscriptionPackage === 1) {
            navigate("/upgradepremium");
        };
        if (subscriptionPackage === 2) {
            navigate("/upgradebusiness");
        };
        if (subscriptionPackage === 3) {
            navigate("/upgradeseller");
        }

    }

    const manageUnsubscribe = async () => {
        if (invoicesData.length > 0 && userData[0]) {
            dispatch(updateAccountFunc({ payload: { id: userData[0].id, unsubscribeTimer: invoicesData[0].date }, token: localStorage.getItem("token") }))
                .then((res) => {
                    if (res.payload.statusCode === 200) {
                        window.location.reload();
                        /* dispatch(unsubscribePaypalFunc({ token: localStorage.getItem("token"), idBilling: invoicesData[0].idBilling })) */
                    }
                })

        }
    }

    useEffect(() => {
        dispatch(decodeTkn());
        const dcdTkn = jwtDecode(localStorage.getItem("token"), process.env.JWT_SECRET);
        if (userData.length === 0) {
            dispatch(getSingleUserFunc({ id: dcdTkn.id, token: localStorage.getItem("token") }));
        }
    }, [])

    useEffect(() => {
        if (invoicesData.length > 0) {
            const seconds = (new Date().getTime() - new Date(invoicesData[0].date).getTime()) / 1000;
            const myRemainingPercent = (Math.round((2592000 - seconds) / 86400) / 0.3);
            const myRemainingTime = Math.round((2592000 - seconds) / 86400);
            setRemainingPercentage(myRemainingPercent);
            setRemainingDays(myRemainingTime);
        }
    }, [invoicesData])

    useEffect(() => {
        if (userData.length > 0) {
            setSubscriptionPackage(userData[0].package)
            if (userData[0].unsubscribeTimer) { setIsUserUnsubscribed(true) }
        }
    }, [userData])



    return (
        <div>
            <div className='d-flex justify-content-center gap-2 pt-5'>
                <div className={`${subscriptionPackage === 1 ? "myBgDark" : "bg-secondary"} d-flex align-items-center justify-content-center packageIcon100px myBorderYellow myCursor`}
                    onClick={() => { setSubscriptionPackage(1); setUnsubscribe(false); setUpgrade(false) }}>
                    <i className={`bi bi-lightning-charge-fill ${subscriptionPackage === 1 ? "myYellowColor" : "myLightGrayColor"} myFontSize3Em`} ></i>
                </div>
                <div className={`${subscriptionPackage === 2 ? "myBgDark" : "bg-secondary"} d-flex align-items-center justify-content-center packageIcon100px myBorderAqua myCursor`}
                    onClick={() => { setSubscriptionPackage(2); setUnsubscribe(false); setUpgrade(false) }}>
                    <i className={`bi bi-droplet-fill ${subscriptionPackage === 2 ? "myAquaColor" : "myLightGrayColor"} myFontSize3Em`} ></i>
                </div>
                <div className={`${subscriptionPackage === 3 ? "myBgDark" : "bg-secondary"} d-flex align-items-center justify-content-center packageIcon100px myBorderFucsiaRed myCursor`}
                    onClick={() => { setSubscriptionPackage(3); setUnsubscribe(false); setUpgrade(false) }}>
                    <i className={`bi bi-fire ${subscriptionPackage === 3 ? "myFucsiaRed" : "myLightGrayColor"} myFontSize3Em`} ></i>
                </div>
            </div>
            {
                subscriptionPackage !== 0 &&
                <div className='d-flex mt-3 align-items-center flex-column'>
                    <div className='myBgLightGray mt-4 p-3 w-100 py-5 d-flex align-items-center flex-column'>

                        <div className='myMaxW700 w-100'>
                            <div className='fw-normal text-light myBgDark rounded-5 px-4 py-2 d-flex justify-content-between'>

                                {
                                    subscriptionPackage === 1 ?
                                        <div><i className="bi bi-lightning-charge-fill myYellowColor"></i> Premium</div>
                                        : subscriptionPackage === 2 ?
                                            <div><i className="bi bi-droplet-fill myAquaColor"></i> Business</div>
                                            : <div><i className="bi bi-fire myFucsiaRed"></i> Seller</div>
                                }

                                {
                                    userData[0] && userData[0].package === subscriptionPackage ?
                                        isUserUnsubscribed ?
                                            <div><i className="bi bi-check-lg myYellowColor"> Unsubscribed</i></div>
                                            : <div><i className="bi bi-check-lg myChatColor"></i> active</div>
                                        : null
                                }
                                {
                                    userData[0] && userData[0].package !== subscriptionPackage ?
                                        <div>
                                            {
                                                packagesJSON.map((el, index) => {
                                                    if (subscriptionPackage === el.id) {
                                                        return (
                                                            <div className={`${el.color} d-flex align-items-baseline gap-1 myBgDark rounded-5`} key={`packageSubscription0-${index}`}>
                                                                <h5 className='fw-light '>{el.price}€</h5>
                                                                <p className='mb-0'>/month</p>
                                                            </div>

                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                        : null
                                }
                                {
                                    userData[0] && userData[0].package === subscriptionPackage && !isUserUnsubscribed ?
                                        <i className='text-secondary myCursorHover' onClick={() => setUnsubscribe(true)}>usubscribe</i>
                                        : userData[0].package === 0 ?
                                            <i className='myChatColor myCursorHover' onClick={manageSubscription}>Subscribe</i>
                                            :
                                            <i className='myChatColor myCursorHover' onClick={() => setUpgrade(true)}><i className="bi bi-arrow-up"></i>Upgrade</i>
                                }

                            </div>
                        </div>

                        {
                            userData[0] && userData[0].package === subscriptionPackage ?
                                <div className='py-4 pb-3 d-flex justify-content-center align-items-center w-100 myMaxW700'>
                                    <div className='border myBgWhite rounded-5 mx-3 w-75'>
                                        <div className={`${subscriptionPackage === 1 ? "myBgYellow" : subscriptionPackage === 2 ? "myBgAqua" : "myBgFucsiaRed"} rounded-5 position-relative`} style={{ height: "10px", width: `${remainingPercentage}%` }}>
                                            <div className={`h-100 ${remainingPercentage > 5 ? "percentageBarGlow" : ""} top-0 position-absolute`} style={{ width: "5vw", zIndex: 9 }}></div>
                                        </div>
                                    </div>
                                    <div className='w-25 d-flex align-items-center gap-2'>
                                        <h2 className='fw-light'>{remainingDays}</h2>
                                        <span className='' style={{ lineHeight: "16px" }}>{isUserUnsubscribed ? "days left" : "days left for next invoice"}</span>
                                    </div>
                                </div>
                                : null
                        }

                        {
                            unsubscribe ?
                                <div className='text-center mt-3 p-4 bg-light'>
                                    <h4 className='mb-3'>{`If you unsubscribe, your current subscription plan will be deleted in ${remainingDays} days from now.`} </h4>
                                    <p> You can take advantages of the services you already paid until the end of the subscription plan.
                                    </p>
                                    <h5>Are you sure?</h5>
                                    <div className='mt-3'>
                                        <Button className='p-3 py-1 rounded-5 text-light me-3' variant='secondary' onClick={() => setUnsubscribe(false)}>cancel</Button>
                                        <Button className='myBgFucsiaRed p-3 py-1 rounded-5 text-light' variant='light'
                                            onClick={manageUnsubscribe}>unsubscribe</Button>
                                    </div>
                                </div>
                                : null
                        }

                        {
                            upgrade ?
                                <div className='text-center mt-4 p-4 bg-light'>
                                    <h4 className='myFucsiaRed mb-3'>Upgrading to this plan will cancel the current subscription plan immediately.</h4>
                                    <p><b>This means that you will not be refunded the remaining days of the month you have already paid.</b><br />
                                        Unsubscription will be considered the same as contract expiration and you will no longer be entitled to use your chosen services for the remaining days of the paid month.<br />
                                        To make the most of paid services,<b> we recommend upgrading packages close to the expiration of the current active plan.</b>
                                    </p>
                                    <h5>Are you sure?</h5>
                                    <div className='mt-3'>
                                        <Button className='p-3 py-1 rounded-5 text-light me-3' variant='secondary' onClick={() => setUnsubscribe(false)}>cancel</Button>
                                        <Button className='myBgChat p-3 py-1 rounded-5' variant='light'
                                            onClick={manageUpgrade}><i className="bi bi-arrow-up"></i>Upgrade</Button>
                                    </div>
                                </div>
                                : null
                        }



                        {
                            packagesJSON.map((el, index) => {
                                if (el.id === subscriptionPackage) {
                                    return (
                                        <div key={`packageSubscription1-${index}`} className='d-flex flex-column align-items-center mt-3'>
                                            <ul className='mt-3 text-dark' style={{ lineHeight: "2rem" }}>
                                                <li>Unlimited <b>Chat Contacts</b></li>
                                                <li><b>{el.weekly} Weekly</b> announcement simultaneously</li>
                                                {el.monthly ? <li><b>{el.monthly} Monthly</b> announcement {el.monthly !== 1 ? "simultaneously" : ""}</li> : null}
                                                <li>Up to <b>{el.dailyAnnouncement}</b> daily announcement</li>
                                            </ul>
                                        </div>
                                    )
                                }
                            })
                        }

                        {
                            isUserUnsubscribed && subscriptionPackage !== 0 ?
                                <h6 className='fw-light'>Your {subscriptionPackage === 1 ? "PREMIUM" : subscriptionPackage === 2 ? "BUSINESS" : "SELLER"} features will be removed in {remainingDays} days</h6>
                                : null
                        }

                        {
                            errorMessage ?
                                <div className='d-flex align-items-center gap-4 myBgFucsiaRed p-3 px-4 text-light fw-bold'>
                                    <i class="bi bi-exclamation-triangle-fill display-5"></i>
                                    <div className=''>
                                        {errorMessage}
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </div>
            }

        </div>
    )
}

export default PackagesSubscription