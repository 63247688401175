import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import PaymentMethods from '../components/PaymentMethods';

const UpgradeBusinessPage = () => {
  return (
    <MainLayout>
      <PaymentMethods product={{id: 2, type: "BUSINESS", planId: "P-9BK72899FK098531UM2LZ5LY", planIdStripe: "price_1Pg463RsEhNEuZA12EQM26sE", price: "19.99", quantity: 1, currency: "EUR"}}/>
    </MainLayout>
  )
}

export default UpgradeBusinessPage