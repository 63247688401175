import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import Checkout from '../components/Checkout';
import { useParams } from 'react-router';

const BuySlotsPage = () => {
    const params = useParams();
    return (
        <MainLayout>
            <Checkout product={{ id: params.type === "WEEKLY" ? 11 : 22, type: params.type, planId: "", planIdStripe: params.type === "WEEKLY" ? "price_1PhuqURsEhNEuZA1qXA3HX6I":"price_1PhurVRsEhNEuZA15Npc15wJ", price: params.type === "WEEKLY" ? "5.99" : "12.99", quantity: +params.qnt, currency: "EUR" }} />
        </MainLayout>
    )
}

export default BuySlotsPage