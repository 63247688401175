import React, { useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons, FUNDING, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createInvoiceFunc } from '../states/invoiceState';
import { updateAccountFunc } from '../states/signinState';

const PaypalSubscribe = ({ product }) => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const invoiceAnagraphic = useSelector((state) => state.invoiceAnagraphic.invoiceAnagraphic);
    const userData = useSelector((state) => state.login.userData);
    const [paymentError, setPaymentError] = useState("");


    const initialOptions = {
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: product.currency,
        intent: "subscription",
        vault: true
    };

    const apiUrl = process.env.REACT_APP_SERVER_ADDRESS

    const createOrder = async () => {
        // Order is created on the server and the order id is returned

        return fetch(`${apiUrl}/api/orders`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            // use the "body" param to optionally pass additional order information
            // like product skus and quantities
            body: JSON.stringify({
                product: {
                    description: "pricePackage.description",
                    cost: product.price
                }
            }),
        })
            .then((response) => response.json())
            .then((order) => order.id);
    };

    const createSubscription = (data, actions) => {
        return actions.subscription.create({
            'plan_id': product.planId,
        });
    };

    const paypalOnError = (err) => {
        console.log(err)
    }

    const onApprove = async (data, actions) => {
        // Order is captured on the server and the response is returned to the browser

        const response = actions.subscription.get().then((res) => {
            if (res) {
                if (res.status === "ACTIVE") {
                    const packageId = res.plan_id === "P-8VY29215LE517393HM2HGJ6Y" ?
                        1 : res.plan_id === "P-9BK72899FK098531UM2LZ5LY" ?
                            2 : res.plan_id === "P-5RM69546G9333345PM2LZ57A" ?
                                3 : 0;

                    const payload = {
                        idBilling: res.id,
                        billingCode: null,
                        status: 0,
                        planId: res.plan_id,
                        idAnagraph: invoiceAnagraphic[0].id,
                        idOwner: invoiceAnagraphic[0].idOwner,
                        payerId: res.subscriber.payer_id,
                        payerEmail: res.subscriber.email_address,
                        payerName: JSON.stringify(res.subscriber.name),
                        description: packageId === 1 ? "Premium Subscription" : packageId === 2 ? "Business Subscription" : packageId === 3 ? "Seller Subscription" : "",
                        quantity: res.quantity,
                        netAmount: res.billing_info.last_payment.amount.value,
                        currency: res.billing_info.last_payment.amount.currency_code,
                        tax: invoiceAnagraphic[0].country === "IT" ? "22" : null,
                        fee: null,
                        method: "PayPal",
                        payerMethod: "",
                        extraInfo: null,
                        invoiceUrl: ""

                    }
                    dispatch(createInvoiceFunc({ payload: payload, token: localStorage.getItem("token") }))
                        .then((res) => {
                            if (res.payload.statusCode === 201) {
                                dispatch(updateAccountFunc({ payload: { ...userData[0], package: packageId }, token: localStorage.getItem("token") }))
                                    .then((res) => {
                                        if (res.payload.statusCode === 200) {
                                            navigate("/account")
                                        }
                                    })
                            }
                        })
                } else {
                    setPaymentError("Payment has been rejected. No subscription has been activated. Try again or check on your paypal app if any additional informations are been reported. ")
                }

            }
        })
        return response

    };



    return (
        <div className='d-flex justify-content-center'>
            {
                !paymentError ?
                    <div className='w-100 myMaxW700 p-4'>
                        <PayPalScriptProvider options={initialOptions}>
                            <PayPalButtons
                                createSubscription={(data, details) => createSubscription(data, details)}
                                onApprove={(data, actions) => onApprove(data, actions)}
                                catchError={paypalOnError}
                                onError={paypalOnError}
                                onCancel={paypalOnError}
                                fundingSource={FUNDING.PAYPAL}

                            />
                        </PayPalScriptProvider>
                    </div>
                    :
                    <h4 className='fw-light text-center bg-danger p-3 py-4 text-light'>{paymentError}</h4>

            }
        </div>
    )
}

export default PaypalSubscribe