import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAnnouncementsByIdOwnerFunc } from '../states/storeState';
import { jwtDecode } from 'jwt-decode';
import CardPenRejAnnouncementReduced from './CardPenRejAnnouncementReduced';
import CardPenRejAnnouncementLine from './CardPenRejAnnouncementLine';
import { setIsLogged } from '../states/loginState';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { clearCategoriesProduct } from '../states/generalState';

const _Announcements = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allUserAnnouncements = useSelector((state) => state.myStore.allData);
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const [dcdTkn, setDcdTkn] = useState("");
    const [typeOfView, setTypeOfView] = useState(0);

    const token = localStorage.getItem("token");
    const tkn = jwtDecode(token, process.env.JWT_SECRET);

    useState(() => {
        dispatch(getAllAnnouncementsByIdOwnerFunc({ idOwner: tkn.id, token: token }))
            .then((res) => {
                if (res.payload.statusCode === 408) {
                    dispatch(setIsLogged(false));
                    navigate('/login');
                    localStorage.clear()
                }
            });
    }, [])

    
    return (
        <div>
            {
                allUserAnnouncements && allUserAnnouncements.length !== 0 ?
                    <div>

                        <div className='d-flex justify-content-around align-items-center mt-3'>
                            <div>
                                <Link className='noDecoration' to={"/createannouncement"} onClick={()=>{dispatch(clearCategoriesProduct()) }}>
                                    <div className='d-flex flex-column align-items-center'>
                                        <i className="bi bi-plus-circle-fill display-3 text-primary"></i>
                                        <p className='m-0 fw-bold'>Add New</p>
                                    </div>
                                </Link>
                            </div>
                            <div>
                                <div className='d-flex justify-content-center align-items-center gap-2' style={{ fontSize: "1.5rem" }}>
                                    <div className={`myCursor border p-2 py-1 ${typeOfView === 0 ? "bg-dark text-light" : ""} rounded-1`} onClick={() => setTypeOfView(0)}><i className="bi bi-columns-gap myCursor"></i></div>
                                    <div className={`myCursor border p-2 py-1 ${typeOfView === 1 ? "bg-dark text-light" : ""} rounded-1`} onClick={() => setTypeOfView(1)}><i className="bi bi-list-task myCursor"></i></div>
                                </div>
                                <div className='d-flex align-items-center justify-content-center mt-1'>
                                    <div>{allUserAnnouncements.length} announcements</div>
                                </div>
                            </div>
                        </div>


                        <div className='d-flex flex-wrap justify-content-center align-items-center mb-5 mt-3 pb-5 px-1'>
                            {
                                allUserAnnouncements && allUserAnnouncements.map((el, index) => {
                                    if (typeOfView === 0) {
                                        return <CardPenRejAnnouncementReduced idOwn={dcdTkn.id} key={`account2.1-${index}`} singleData={el} isLoading={isLoading} />
                                    } else {
                                        return <CardPenRejAnnouncementLine idOwn={dcdTkn.id} key={`account2.2-${index}`} singleData={el} isLoading={isLoading} />
                                    }
                                })
                            }
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default _Announcements