import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import PaymentMethods from '../components/PaymentMethods';

const UpgradeSellerPage = () => {
  return (
    <MainLayout>
      <PaymentMethods product={{id: 3, type: "SELLER", planId: "P-5RM69546G9333345PM2LZ57A", planIdStripe: "price_1Pg46iRsEhNEuZA1TafYiCTV", price: "29.99", quantity: 1, currency: "EUR"}}/>
    </MainLayout>
  )
}

export default UpgradeSellerPage