import React, { useEffect } from 'react';
import Checkout from './Checkout';

const PaymentMethods = ({product}) => {

 
  return (
    <Checkout product={product} />
  )
}

export default PaymentMethods