import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardFavouritesAnnouncement from './CardFavouritesAnnouncement';
import { jwtDecode } from 'jwt-decode';
import { getUserOutletFunc } from '../states/outletStore';
import { getAnnouncementsByIdFunc } from '../states/storeState';

const _Favourites = () => {

    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const outletData = useSelector((state) => state.myStore.outletData);
    const tkn = jwtDecode(token, process.env.JWT_SECRET);

    useEffect(() => {
        dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
            .then((res) => {
                if (res.payload.statusCode !== 408 && res.payload.data.length > 0 && res.payload.data[0].outletLikes) {
                    dispatch(getAnnouncementsByIdFunc({ idSet: res.payload.data[0].outletLikes, token: token }));
                }
            })
    }, [])

    return (
        <div>
            <div className='d-flex flex-wrap justify-content-center align-items-center mb-5 mt-1 pb-5 px-1'>
                {
                    outletData && outletData.map((el, index) => {
                        return <CardFavouritesAnnouncement idOwn={tkn.id} key={`favourites1-${index}`} singleData={el} isLoading={isLoading} />
                    })
                }
            </div>
        </div>
    )
}

export default _Favourites