import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import PaymentSuccessProgressBar from '../components/PaymentSuccessProgressBar';

const PaymentSuccessProgressBarPage = () => {
  return (
    <MainLayout>
        <PaymentSuccessProgressBar/>
    </MainLayout>
  )
}

export default PaymentSuccessProgressBarPage