import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import _Invoices from '../components/_Invoices';

const InvoicesPage = () => {
    return (
        <MainLayout>
            <_Invoices />
        </MainLayout>
    )
}

export default InvoicesPage