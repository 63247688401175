import React from 'react';
import MainLayout from '../Layouts/MainLayout';
import PaymentMethods from '../components/PaymentMethods';

const UpgradePremiumPage = () => {
  return (
    <MainLayout>
      <PaymentMethods product={{id: 1, type: "PREMIUM", planId: "P-8VY29215LE517393HM2HGJ6Y", planIdStripe: "price_1PeGCpRsEhNEuZA1NCElTzDE", price: "9.99", quantity: 1, currency: "EUR"}}/>
    </MainLayout>
  )
}

export default UpgradePremiumPage