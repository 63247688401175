import React, { useEffect } from 'react';
import MainLayout from '../Layouts/MainLayout';
import PaymentSucces from '../components/PaymentSuccess';

const PaymentSuccessPage = () => {
 
  return (
    <MainLayout>
        <PaymentSucces />
    </MainLayout>
  )
}

export default PaymentSuccessPage