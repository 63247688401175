import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleUserFunc } from '../states/loginState';
import { getAllAnnouncementsByIdOwnerFunc, getAnnouncementsByIdFunc } from '../states/storeState';
import { jwtDecode } from 'jwt-decode';
import CardPenRejAnnouncementReduced from './CardPenRejAnnouncementReduced';
import CardPenRejAnnouncementLine from './CardPenRejAnnouncementLine';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { verifyMailFunc, updateAccountFunc } from '../states/signinState';
import CategoriesPreferences from './CategoriesPreferences';
import AccountEditAnagraphic from './AccountEditAnagraphic';
import { setIsLogged } from '../states/loginState';
import { useNavigate } from 'react-router';
import { getUserOutletFunc } from '../states/outletStore';
import CardFavouritesAnnouncement from './CardFavouritesAnnouncement';
import CardChatAnnouncement from './CardChatAnnouncement';
import ChatAnnouncement from './ChatAnnouncement';
import { getAllChatsNotifyByIdOwnerUserFunc, areThereNotify } from '../states/chatState';
import { Link } from 'react-router-dom';
import { decodeTkn } from '../states/generalState';
import { stripeUnsubscribeFunc } from '../states/unsubscribeState';
import packagesJSON from '../assets/JSON/packages.json';
import { createSlotsFunc } from '../states/slotState';


const _Chat = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.login.userData);
    const allUserAnnouncements = useSelector((state) => state.myStore.allData);
    const isLoading = useSelector((state) => state.myStore.isLoading);
    const loading = useSelector((state) => state.signin.loading);
    const outletData = useSelector((state) => state.myStore.outletData);
    const myChatState = useSelector((state) => state.chat.myChatState);
    const decodedTkn = useSelector((state) => state.general.dcdTkn);
    const [dcdTkn, setDcdTkn] = useState("");
    const [typeSubMenu, setTypeSubMenu] = useState(0);
    const [isFavouritesChat, setIsFavouritesChat] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);
    const [openChat, setOpenChat] = useState(false);
    const [idChat, setIdChat] = useState(null);
    const chatRef = useRef()

    const token = localStorage.getItem("token");
    const tkn = jwtDecode(token, process.env.JWT_SECRET);

    useEffect(() => {
        dispatch(getSingleUserFunc({ id: tkn.id, token: token }));
        dispatch(getAllChatsNotifyByIdOwnerUserFunc({ idOwnerUser: tkn.id, token: token }))
            .then((res) => {
                if (res.payload.statusCode === 200 && res.payload.data.length > 0) {
                    dispatch(areThereNotify({ chats: res.payload.data, idOwner: tkn.id }))
                }
            })
        dispatch(getAllAnnouncementsByIdOwnerFunc({ idOwner: tkn.id, token: token }))
            .then((res) => {
                if (res.payload.statusCode === 408) {
                    dispatch(setIsLogged(false));
                    navigate('/login');
                    localStorage.clear()
                }
            });
        dispatch(getUserOutletFunc({ idOwner: tkn.id, token: token }))
            .then((res) => {
                if (res.payload.statusCode !== 408 && res.payload.data.length > 0 && res.payload.data[0].outletLikes) {
                    dispatch(getAnnouncementsByIdFunc({ idSet: res.payload.data[0].outletLikes, token: token }));
                }
            })
    }, [])

    useEffect(() => {
        if (myChatState) {
            setTypeSubMenu(myChatState.typeSubMenu);
            setIsFavouritesChat(myChatState.isFavouriteChat);
            setIdChat(myChatState.idChat);
            setOpenChat(myChatState.openChat)
        }
    }, [myChatState]);

    useEffect(() => {
        if (openChat || typeSubMenu === 2) {
            chatRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    }, [openChat, typeSubMenu]);

    return (
        <div ref={chatRef}>

            <div className='d-flex justify-content-center gap-3 mb-2 mt-3'>
                <span className={`${isFavouritesChat ? "myBgFucsiaRed" : "bg-secondary"} fw-bold text-light myCursor p-1 px-3 rounded-5`} onClick={() => setIsFavouritesChat(true)}>Favourites</span>
                <span className={`${!isFavouritesChat ? "myBgAqua text-dark" : "bg-secondary text-light"} fw-bold myCursor p-1 px-3 rounded-5`} onClick={() => setIsFavouritesChat(false)}>My Announcements</span>
            </div>
            <hr className='mb-0' />

            {
                width < 1100 ? //CHAT MOBILE VERSION
                    <div className='d-flex'>
                        {
                            isFavouritesChat ?
                                <div className='d-flex align-items-center flex-column w-100 pb-5 mb-3'>
                                    {
                                        outletData && outletData.map((el, index) => {//FAVOURITE CHAT
                                            if (openChat && el.id === idChat) {
                                                return <div key={`account3.1-${index}`} className=' position-relative bg-light border d-flex justify-content-center' >
                                                    <i className="bi bi-chevron-left position-absolute start-0 ms-2 mt-4 pt-2 mx-1 myCursor display-6" onClick={() => { setOpenChat(false); setIdChat(null) }}></i>
                                                    <ChatAnnouncement singleData={el} isLoading={isLoading} idOwn={dcdTkn.id} width={width} />
                                                </div>
                                            } if (!openChat) {
                                                return <div key={`account3.2-${index}`} className='w-100 d-flex justify-content-center' onClick={() => { setOpenChat(true); setIdChat(el.id) }}>
                                                    <CardChatAnnouncement idOwn={dcdTkn.id} singleData={el} isLoading={isLoading} />
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                                :
                                <div className='d-flex align-items-center flex-column w-100 pb-5 mb-3'>
                                    {
                                        allUserAnnouncements && allUserAnnouncements.map((el, index) => {//ANNOUNCEMENT CHAT
                                            if (openChat && el.id === idChat) {
                                                return <div key={`account4.1-${index}`} className='position-relative bg-light border d-flex justify-content-center' >
                                                    <i className="bi bi-chevron-left position-absolute start-0 ms-2 mt-4 pt-2 mx-1 myCursor display-6" onClick={() => { setOpenChat(false); setIdChat(null) }}></i>
                                                    <ChatAnnouncement singleData={el} isLoading={isLoading} idOwn={dcdTkn.id} width={width} />
                                                </div>
                                            } if (!openChat) {
                                                return <div key={`account4.2-${index}`} className='w-100 d-flex justify-content-center' onClick={() => { setOpenChat(true); setIdChat(el.id) }}>
                                                    <CardChatAnnouncement idOwn={dcdTkn.id} singleData={el} isLoading={isLoading} />
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                        }

                    </div>
                    : null
            }


            {
                width >= 1100 ? //CHAT DESKTOP VERSION
                    <div>
                        {
                            isFavouritesChat ?
                                <>
                                    <div className='d-flex myVhChat'>
                                        <div className='d-flex align-items-center flex-column myOverflowY pb-5 mb-3' style={{ minWidth: "400px", maxWidth: "700px" }}>
                                            {
                                                outletData && outletData.map((el, index) => {//FAVOURITE CHAT

                                                    return <div key={`account5-${index}`} className={`w-100 d-flex justify-content-center ${idChat === el.id ? "myBgChatSelected" : ""}`} onClick={() => { setOpenChat(true); setIdChat(el.id) }}>
                                                        <CardChatAnnouncement idOwn={dcdTkn.id} singleData={el} isLoading={isLoading} />
                                                    </div>

                                                })
                                            }
                                        </div>
                                        <div className='w-100'>
                                            {
                                                outletData && outletData.map((el, index) => {
                                                    if (openChat && el.id === idChat) {
                                                        return <div key={`account6-${index}`} className=' position-relative bg-light border d-flex justify-content-center' >
                                                            <i className="bi bi-chevron-left position-absolute start-0 ms-2 mt-4 pt-2 mx-1 myCursor display-6" onClick={() => { setOpenChat(false); setIdChat(null) }}></i>
                                                            <ChatAnnouncement singleData={el} isLoading={isLoading} idOwn={dcdTkn.id} width={width} />
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='d-flex myVhChat'>
                                        <div className='d-flex align-items-center flex-column myOverflowY pb-5 mb-3' style={{ minWidth: "400px", maxWidth: "700px" }}>
                                            {
                                                allUserAnnouncements && allUserAnnouncements.map((el, index) => {//ANNOUNCEMENT CHAT

                                                    return <div key={`account7-${index}`} className={`w-100 d-flex justify-content-center ${idChat === el.id ? "myBgChatSelected" : ""}`} onClick={() => { setOpenChat(true); setIdChat(el.id) }}>
                                                        <CardChatAnnouncement idOwn={dcdTkn.id} singleData={el} isLoading={isLoading} />
                                                    </div>

                                                })
                                            }
                                        </div>
                                        <div className='w-100'>
                                            {
                                                allUserAnnouncements && allUserAnnouncements.map((el, index) => {
                                                    if (openChat && el.id === idChat) {
                                                        return <div key={`account8-${index}`} className='position-relative bg-light border d-flex justify-content-center' >
                                                            <i className="bi bi-chevron-left position-absolute start-0 ms-2 mt-4 pt-2 mx-1 myCursor display-6" onClick={() => { setOpenChat(false); setIdChat(null) }}></i>
                                                            <ChatAnnouncement singleData={el} isLoading={isLoading} idOwn={dcdTkn.id} width={width} />
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                    : null
            }

        </div>
    )
}

export default _Chat