import React, { useEffect } from 'react';
import _Navbar from '../components/_Navbar';
import _Sidebar from '../components/_Sidebar';
import { useSelector } from 'react-redux';

const MainLayout = ({ children }) => {

    const loading1 = useSelector((state)=>state.signin.loading);

    return (
        <>
            {/* <_Navbar /> */}
            {loading1 ? <div className='w-100' style={{ position: "fixed", height: "100%", zIndex: "103", backgroundColor: "rgb(255,255,255,.7)" }}></div> : null}
            <main style={{ minHeight: "80vh" }}>
                {children}
            </main>
            <_Sidebar />
        </>
    )
}

export default MainLayout