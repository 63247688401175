import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/Graphics/mainLogo.png'
import { useDispatch } from 'react-redux';
import { setIsHamMenuOpen } from '../states/generalState';

const _Sidebar = () => {

  const dispatch = useDispatch();
  const [navSection, setNavSection] = useState(0);
  useEffect(()=>{
console.log();

  }, [])

  return (
    <div className='position-fixed w-100 bottom-0 myBgDark rounded-top-4 pt-2' expand="lg" style={{ zIndex: "102", height: "65px", boxShadow: "0px -1px 2px #444" }}>
      <div className='d-flex justify-content-center'>
        <div className='w-100 myMaxW700 text-light d-flex justify-content-around align-items-center h-100 px-4' style={{ fontSize: "1.4rem" }}>
          <div className={`${window.location.pathname === "/favourites" ? "bottomBorderActive":"bottomBorder"}`} onClick={()=>{setNavSection(3)}}><Link className='noLinkStyle' to={"/favourites"}> <i className="bi bi-star-fill"></i></Link></div>
          <div className={`${window.location.pathname === "/chat" ? "bottomBorderActive":"bottomBorder"}`} onClick={()=>{setNavSection(1)}}><Link className='noLinkStyle' to={"/chat"}> <i className="bi bi-chat-dots-fill"></i></Link></div>
          <div className={`${window.location.pathname === "/store" ? "bottomBorderActive":"bottomBorder"}`} onClick={()=>{setNavSection(0)}}><Link className='logo' to={"/"}><img className='m-1' src={Logo} onClick={() => { dispatch(setIsHamMenuOpen(false)) }} /></Link></div>
          <div className={`${window.location.pathname === "/announcements" ? "bottomBorderActive":"bottomBorder"}`} onClick={()=>{setNavSection(2)}}><Link className='noLinkStyle' to={"/announcements"}> <i className="bi bi-heart-fill"></i></Link></div>
          <div className={`${window.location.pathname === "/account" ? "bottomBorderActive":"bottomBorder"}`} onClick={()=>{setNavSection(4)}}><Link className='noLinkStyle' to={"/account"}> <i className="bi bi-person-circle"></i></Link></div>
        </div>
      </div>
    </div>
  )
}

export default _Sidebar