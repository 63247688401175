import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import countriesArray from '../assets/JSON/countriesIso2Arry.json';
import phoneCodes from '../assets/JSON/countryPhoneCodes.json';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getCitiesFunc, searchCity, clearSearchCity } from '../states/geonamesState';
import Spinner from 'react-bootstrap/Spinner';
import { updateAccountFunc } from '../states/signinState';
import { verifyMailFunc } from '../states/signinState';
import { Link, useNavigate } from 'react-router-dom';
import { createInvoiceAnagrapicFunc, updateInvoiceAnagrapicFunc, setInvoiceEditingState, setReloadAnagraphic } from '../states/invoiceAnagraphics';

const AccountEditAnagraphic = ({ userData, isInvoiceAnagraphic }) => {
    const [companyName, setCompanyName] = useState(userData.companyName);
    const [email, setEmail] = useState(isInvoiceAnagraphic ? "" : userData.email);
    const [country, setCountry] = useState("");
    const [city, setCity] = useState(userData.city);
    const [state, setState] = useState(userData.state);
    const [address, setAddress] = useState(userData.address);
    const [streetNumber, setStreetNumber] = useState(userData.streetNumber);
    const [zipCode, setZipCode] = useState(userData.zipCode);
    const [phone, setPhone] = useState(isInvoiceAnagraphic ? null : userData.phone.split("-")[1]);
    const [phoneCode, setPhoneCode] = useState(isInvoiceAnagraphic ? null : userData.phone.split("-")[0]);
    const [verifyCode, setVerifyCode] = useState(Math.ceil(Math.random() * 1000000000));
    const [vat, setVat] = useState(isInvoiceAnagraphic && userData.vat ? userData.vat : "");
    const [sdi, setSdi] = useState(isInvoiceAnagraphic && userData.sdi ? userData.sdi : "");
    const [pec, setPec] = useState(isInvoiceAnagraphic && userData.pec ? userData.pec : "");
    const [taxId, setTaxId] = useState(isInvoiceAnagraphic && userData.taxId ? userData.taxId : "")

    const dispatch = useDispatch();
    const navigate = useNavigate();

    //inputs validation
    const specialCharacter = ["!", "#", "$", "%", "&", "@", "<", ">", "="];
    const isEmailValid = email.includes("@") && email.includes(".") && email.length > 6;
    const [isCityValid, setIsCityValid] = useState(false);

    const allCitiesFiltered = useSelector((state) => state.geonames.allCitiesFiltered);
    const isLoading = useSelector((state) => state.signin.loading);
    const loadingInvoice = useSelector((state) => state.invoiceAnagraphic.isLoading);
    const invoiceAnagraphic = useSelector((state) => state.invoiceAnagraphic.invoiceAnagraphic);
    const dcdTkn = useSelector((state) => state.general.dcdTkn);

    const formCheck = async () => {

        const payload = isInvoiceAnagraphic ?
            {
                idOwner: dcdTkn.id,
                companyName: companyName,
                country: country.split(":")[1],
                city: city,
                state: state,
                address: address,
                streetNumber: streetNumber,
                zipCode: zipCode,
                vat: vat,
                sdi: sdi,
                pec: pec,
                taxId: taxId
            }
            :
            {
                ...userData,
                companyName: companyName,
                email: email,
                country: country.split(":")[1],
                city: city,
                state: state,
                address: address,
                streetNumber: streetNumber,
                zipCode: zipCode,
                phone: `${phoneCode}-${phone.toString()}`
            }


        if (userData.email !== email && !isInvoiceAnagraphic) {
            dispatch(updateAccountFunc({ payload: { ...payload, accountActive: 0 }, token: localStorage.getItem("token") }))
                .then((res) => {
                    if (res.payload.statusCode === 200) {
                        dispatch(verifyMailFunc({ email: email, companyName: companyName, verifyCode: `${userData.id}-${verifyCode}` }))
                            .then((res) => {
                                if (res.payload.statusCode === 200) { window.location.reload() }
                            })
                    }
                })
        } else {

            if (isInvoiceAnagraphic) {

                if (invoiceAnagraphic.length) {
                    dispatch(updateInvoiceAnagrapicFunc({ payload: payload, token: localStorage.getItem("token") }))
                        .then((res) => {
                            if (res.payload.statusCode === 200) { dispatch(setInvoiceEditingState(false)) }
                        })
                } else {
                    dispatch(createInvoiceAnagrapicFunc({ payload: payload, token: localStorage.getItem("token") }))
                        .then((res) => {
                            if (res.payload.statusCode === 200) { dispatch(setReloadAnagraphic(true)) }
                        })
                }

            } else {
                dispatch(updateAccountFunc({ payload: payload, token: localStorage.getItem("token") }))
                    .then((res) => {
                        if (res.payload.statusCode === 200) {
                            window.location.reload()
                        }
                    })
            }
        }

    }

    useEffect(() => {
        let countryExt = "";
        countriesArray.iso2.map((el) => { if (el.split(":")[1] == userData.country) { return countryExt = el } });
        setCountry(countryExt)
    }, [])

    useEffect(() => {
        country && phoneCodes.map((el) => { if (el.code === country.split(":")[1]) { setPhoneCode(el.dial_code) } });
    }, [country])

    return (
        <div>

            <div style={{ maxWidth: "800px"}}>
                <div className="mb-3 d-flex gap-2 align-items-center">
                    <InputGroup >
                        <InputGroup.Text id="basic-addon1"><i className="bi bi-buildings-fill"></i></InputGroup.Text>
                        <Form.Control
                            placeholder="Company Name"
                            aria-label="Company Name"
                            aria-describedby="basic-addon1"
                            onChange={(e) => { setCompanyName(e.target.value) }}
                            value={companyName}
                        />
                    </InputGroup>
                    {companyName ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                </div>

                {
                    isInvoiceAnagraphic ?
                        <div className="mb-3 d-flex gap-2 align-items-center">
                            <InputGroup >
                                {/* <InputGroup.Text id="basic-addon1"><i className="bi bi-buildings-fill"></i></InputGroup.Text> */}
                                <Form.Control
                                    placeholder="VAT"
                                    aria-label="VAT"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => { setVat(e.target.value) }}
                                    value={vat}
                                />
                            </InputGroup>
                            {vat ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                        </div>
                        : null
                }

                {
                    isInvoiceAnagraphic ?
                        <div className="mb-3 d-flex gap-2 align-items-center">
                            <InputGroup >
                                {/* <InputGroup.Text id="basic-addon1"><i className="bi bi-buildings-fill"></i></InputGroup.Text> */}
                                <Form.Control
                                    placeholder="Tax ID"
                                    aria-label="taxId"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => { setTaxId(e.target.value) }}
                                    value={taxId}
                                />
                            </InputGroup>
                            {taxId ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                        </div>
                        : null
                }

                {
                    isInvoiceAnagraphic ?
                        <div className="mb-3 d-flex gap-2 align-items-center">
                            <InputGroup >
                                {/* <InputGroup.Text id="basic-addon1"><i className="bi bi-buildings-fill"></i></InputGroup.Text> */}
                                <Form.Control
                                    placeholder="SDI Code"
                                    aria-label="SDI"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => { setSdi(e.target.value) }}
                                    value={sdi}
                                />
                            </InputGroup>
                            {sdi ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                        </div>
                        : null
                }
                {
                    isInvoiceAnagraphic ?
                        <div className="mb-3 d-flex gap-2 align-items-center">
                            <InputGroup >
                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                <Form.Control
                                    placeholder="pec"
                                    aria-label="pec"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => { setPec(e.target.value) }}
                                    value={pec}
                                />
                            </InputGroup>
                            {pec ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                        </div>
                        : null
                }

                {
                    isInvoiceAnagraphic ?
                        null :
                        <div>
                            <div className="mb-3 d-flex gap-2 align-items-center">
                                <InputGroup >
                                    <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                    <Form.Control
                                        placeholder="email"
                                        aria-label="email"
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => { setEmail(e.target.value) }}
                                        value={email}
                                    />
                                </InputGroup>
                                {isEmailValid ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                            </div>
                            <div className='d-flex flex-wrap justify-content-center gap-3'>
                                <Link to={`/changepssw/${userData.email}/${userData.id}`}><a className='myCursor link-light'>change password</a></Link>
                            </div>
                        </div>
                }

                {/* -------------------- */}

                <div>
                    {
                        isInvoiceAnagraphic ?
                            <h4 className='text-dark fw-light mb-2 mt-4'>Head Office</h4>
                            : <h4 className='text-light fw-light mb-2 mt-4'>Company location</h4>
                    }

                    <div className="mb-3 d-flex gap-2 align-items-center">
                        <InputGroup >
                            <InputGroup.Text id="basic-addon1"><i className="bi bi-globe-americas"></i></InputGroup.Text>
                            <DropdownButton className="mb-3 w-100" variant='dark' id="dropdown-basic-button"
                                title={country ? country.split(":")[0] : "select a country"}>
                                {
                                    countriesArray && countriesArray.iso2.map((el, index) => {
                                        return <Dropdown.Item key={`accounteditanagraphic1-${index}`} onClick={() => { setCountry(el); dispatch(getCitiesFunc(el.split(":")[1])); dispatch(clearSearchCity()); setCity(""); setIsCityValid(false) }}>{el.split(":")[0]}</Dropdown.Item>
                                    })
                                }
                            </DropdownButton>
                        </InputGroup>
                        {country ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                    </div>

                    <div className="mb-3">
                        <div className='d-flex gap-2 align-items-center'>
                            <InputGroup >
                                <InputGroup.Text id="basic-addon1"><i className="bi bi-geo-fill"></i></InputGroup.Text>
                                <Form.Control
                                    disabled={country ? false : true}
                                    placeholder="City"
                                    aria-label="City"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => { dispatch(searchCity(e.target.value)); setCity(e.target.value); setIsCityValid(false) }}
                                    value={city}
                                />
                            </InputGroup>
                            {isCityValid && city ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                        </div>
                        {
                            allCitiesFiltered.length !== 0 ?
                                <div className='position-relative'>
                                    <div className='border w-100 p-3 customDropdown position-absolute bg-light text-dark'>
                                        {
                                            allCitiesFiltered && allCitiesFiltered.map((el, index) => {
                                                return <div key={`accounteditanagraphic2-${index}`} className='px-2 rounded-5 myCursor' onClick={() => { setCity(el.name); dispatch(clearSearchCity()); setIsCityValid(true) }}>{el.name}</div>
                                            })
                                        }
                                    </div>
                                </div>
                                : null
                        }
                    </div>

                    <div className="mb-3 d-flex gap-2 align-items-center">
                        <InputGroup >
                            <InputGroup.Text id="basic-addon1"><i className="bi bi-map-fill"></i></InputGroup.Text>
                            <Form.Control
                                disabled={country && city ? false : true}
                                placeholder="State/Province"
                                aria-label="State/Province"
                                aria-describedby="basic-addon1"
                                onChange={(e) => { setState(e.target.value) }}
                                value={state}
                            />
                        </InputGroup>
                        {state ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                    </div>

                    <div className="mb-3 d-flex gap-2 align-items-center">
                        <InputGroup >
                            <InputGroup.Text id="basic-addon1"><i className="bi bi-geo-alt-fill"></i></InputGroup.Text>
                            <Form.Control
                                disabled={country && city ? false : true}
                                placeholder="Address"
                                aria-label="Address"
                                aria-describedby="basic-addon1"
                                onChange={(e) => { setAddress(e.target.value) }}
                                value={address}
                            />
                        </InputGroup>
                        {address ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                    </div>

                    <div className="mb-3 d-flex gap-2 align-items-center">
                        <InputGroup >
                            <InputGroup.Text id="basic-addon1"><i className="bi bi-signpost-fill"></i></InputGroup.Text>
                            <Form.Control
                                disabled={country && city ? false : true}
                                placeholder="Street Number"
                                aria-label="Street Number"
                                aria-describedby="basic-addon1"
                                onChange={(e) => { setStreetNumber(e.target.value) }}
                                value={streetNumber}
                            />
                        </InputGroup>
                        {streetNumber ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                    </div>

                    <div className="mb-3 d-flex gap-2 align-items-center">
                        <InputGroup>
                            <InputGroup.Text id="basic-addon1"><i className="bi bi-mailbox2-flag"></i></InputGroup.Text>
                            <Form.Control
                                disabled={country && city ? false : true}
                                placeholder="Zip Code"
                                aria-label="Zip Code"
                                aria-describedby="basic-addon1"
                                onChange={(e) => { setZipCode(e.target.value) }}
                                value={zipCode}
                            />
                        </InputGroup>
                        {zipCode ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                    </div>

                    {
                        isInvoiceAnagraphic ?
                            null :
                            <div className="mb-3 d-flex gap-2 align-items-center">
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1"><i className="bi bi-telephone-fill"></i></InputGroup.Text>
                                    <DropdownButton disabled={country ? false : true} className="mb-3 w-100" variant='dark' id="dropdown-basic-button" title={phoneCode ? phoneCode : "select a prefix"}>
                                        {
                                            phoneCodes.map((el, index) => {
                                                return <Dropdown.Item key={`accounteditanagraphic3-${index}`} onClick={() => setPhoneCode(el.dial_code)}>{el.dial_code} ({el.name})</Dropdown.Item>
                                            })
                                        }
                                    </DropdownButton>
                                    <Form.Control
                                        disabled={country ? false : true}
                                        type='number'
                                        placeholder="Phone"
                                        aria-label="Phone"
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => setPhone(e.target.value)}
                                        value={phone}
                                    />
                                </InputGroup>
                                {phone.length > 5 ? <i className="bi bi-check-circle-fill ms-2 myChatColor"></i> : null}
                            </div>
                    }

                    {
                        isInvoiceAnagraphic && !invoiceAnagraphic.length && <p className='text-center'>This is the first time you pay with this account. Congratulations! <br />
                        To proceed you need to insert correct invoice data and click to +Add.  </p>
                    }

                    <div className='d-flex justify-content-center pt-4 pb-3'>
                        {
                            isLoading || loadingInvoice ?
                                <div>
                                    <Spinner variant='secondary' animation="border" />
                                </div>
                                :
                                <h5>
                                    <i className={`bi ${invoiceAnagraphic.length || !isInvoiceAnagraphic ? "bi-check-lg" : "bi-plus-lg"} myCursor me-3 px-3 rounded-5 py-2 myBgChat shadow-sm text-dark`} onClick={() => { formCheck(); localStorage.removeItem("editId") }}> {invoiceAnagraphic.length || !isInvoiceAnagraphic ? "Confirm" : "Add"}</i>
                                </h5>
                        }
                    </div>

                </div>

            </div>

        </div>
    )
}

export default AccountEditAnagraphic