import React from 'react';

const SlotCardAnn = ({ el }) => {
    return (
        <div className={` rounded-4 p-3 myMaxW300 text-light ${el.id === 1 ? "myGradientFreely" : el.id === 2 ? "myGradientWeekly" : "myGradientMonthly"}`}>
            <h3 className='text-center text-dark'>{el.id === 1 ? <i className="bi bi-gift-fill"></i> : el.id === 2 ? <i className="bi bi-7-circle-fill"></i> : <i className="bi bi-moon-stars-fill"></i>} {el.name}</h3>
            <hr className='mt-2' />
            <div>
                <ul>
                    <li><p className='mb-1'><b>{el.bold1 ? el.bold1 : ""}</b>{el.text1}</p></li>
                    {el.text2 ? <li><p className='mb-1'><b>{el.bold2 ? el.bold2 : ""}</b>{el.text2}</p></li> : null}
                    {el.text3 ? <li><p className='mb-1'><b>{el.bold3 ? el.bold3 : ""}</b>{el.text3}</p></li> : null}
                </ul>

                <p className='text-center'><span className='display-6'>{el.price}</span>{el.period}</p>

                <p className='mt-4 myFontSize6'>{el.extra}</p>
            </div>
        </div>
    )
}

export default SlotCardAnn