import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

const PaymentSucces = () => {

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(()=>navigate(`/paymentsuccessprogressbar`), 1000)
  }, [])
  return (
    <div className='d-flex justify-content-center mt-5'>
      <div className='display-6 text-success'>
      Payment Success <i className="bi bi-check2-circle "></i>
      </div>
    </div>
  )
}

export default PaymentSucces